// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.95.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flexColumn,
.arcadeWidget {
  display: flex;
  flex-direction: column;
}
.flexColumn > *:not(:first-child) {
  margin-top: 10px;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexRow > *:not(:first-child) {
  margin-left: 10px;
}
.flexSpacer {
  flex: 1 1 0;
}
.arcadeWidget:global(.nkl-paper) {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  text-decoration: inherit;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
`, "",{"version":3,"sources":["webpack://./src/widget/view/common/flex.less","webpack://./src/widget/view/style.less"],"names":[],"mappings":"AAEA;;EACE,aAAA;EACA,sBAAA;ACAF;ADEE;EACE,gBAAA;ACAJ;ADIA;EACE,aAAA;EACA,mBAAA;ACFF;ADIE;EACE,iBAAA;ACFJ;ADMA;EACE,WAAA;ACJF;AAVI;EACI,cAAA;EACA,oBAAA;EACA,kBAAA;EACA,wBAAA;AAYR;AACA,4FAA4F;AAC5F,6CAA6C;AAC7C,sBAAsB;AACtB,6FAA6F","sourcesContent":["@import \"constants.less\";\n\n.flexColumn {\n  display: flex;\n  flex-direction: column;\n\n  & > *:not(:first-child) {\n    margin-top: @padding;\n  }\n}\n\n.flexRow {\n  display: flex;\n  flex-direction: row;\n\n  & > *:not(:first-child) {\n    margin-left: @padding;\n  }\n}\n\n.flexSpacer {\n  flex: 1 1 0;\n}\n","@import \"common/constants.less\";\n@import \"common/flex.less\";\n\n.arcadeWidget {\n    &:extend(.flexColumn);\n\n    // Pick up the text styling of the immediate parent so it looks more integrated\n    &:global(.nkl-paper) {\n        color: inherit;\n        font-family: inherit;\n        font-size: inherit;\n        text-decoration: inherit;\n    }\n}\n\n@root-entry-name: default;@____autoThemeVariablesFileHack: true; @import \"~antd/es/style/themes/index\";@ant-prefix: nkl;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
